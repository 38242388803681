.Route {
  padding: 3px 0;
  text-align: center;
}

.Card {
  padding: 3px 0;
}

.Card .header {
  padding: 1px 0;
  text-align: left;
  background-color: #AAADC4;
  color: #110B11;
}

.Card .body {
  padding: 3px 0;
  text-align: left;
  background-color: #E6E8E6;
  color: #110B11;
}

.RouteButton {
  padding: 3px 0;
  margin-bottom: 2px;
  margin-right: 5px;
}

.formLabel {
  font-size: 0.9rem;
  font-weight: bold;
  padding: .2rem;
  display: inline-block;
}